<template>
  <div>
    <a-spin :spinning="loading">
      <router-link to="/mendian">
        <a-button type="primary"> < 返回 </a-button>
      </router-link>
      <div class="mt24 flex space">
        <div class="base-app-item" v-for="(item, index) in base_applications">
          <div class="pd20 flex alcenter space">
            <div class="base-app-l flex alcenter">
              <img v-if="item.logo != null" :src="item.logo" />
              <img
                v-else
                src="../assets/image/application_icon_base01@2x.png"
              />
              <div class="ml20 base-app-info">
                <div class="flex alcenter">
                  <span class="ft18 ftw500 cl-main">{{ item.name }}</span>
                  <div class="test-tag ml10">
                    免费试用{{ item.trial_days }}天
                  </div>
                </div>
                <div class="mt16 cl-main ftw500 ft14">适用场景：</div>

                <div class="mt8 ft12 cl-info" style="line-height: 18px">
                  {{ item.scene }}
                </div>
              </div>
            </div>
            <div>
              <a-button @click="addMendian(item.app_id)" type="primary" ghost
                >免费试用</a-button
              >
            </div>
          </div>
          <img
            v-if="item.is_tui == 1"
            class="tui"
            src="../assets/image/label_recommend@2x.png"
          />
        </div>
      </div>

      <!--<div class="mt40 flex alcenter">
        <span class="ft20 cl-main ftw500">行业深度解决方案</span>
        <div class="app-cat flex alcenter">
          <div class="app-cat-item on" v-if="search.type == 1">全部</div>
          <div class="app-cat-item clickAct" v-else @click="selectIt(1)">
            全部
          </div>
          <template v-for="(item, index) in type_list">
            <div
              class="app-cat-item on"
              v-if="item.id == search.type && item.id != 1"
            >
              {{ item.name }}
            </div>
            <div
              class="app-cat-item clickAct"
              v-if="item.id != search.type && item.id != 1"
              @click="selectIt(item.id)"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
      </div>-->
      <div class="mt24">
        <a-row :gutter="[20, 20]">
          <a-col :span="6" v-for="(item, index) in applications" :key="index">
            <div class="scheme-item">
              <div>
                <div class="text-center">
                  <img
                    v-if="item.logo != null"
                    width="80"
                    height="80"
                    :src="item.logo"
                  />
                  <img
                    v-else
                    width="80"
                    height="80"
                    src="../assets/image/application_icon_scheme01@2x.png"
                  />
                </div>
                <div class="text-center mt20 ft18 ftw500 cl-main">
                  {{ item.name }}
                </div>
                <div class="flex center mt10">
                  <div class="test-tag">免费试用{{ item.trial_days }}天</div>
                </div>
                <div
                  class="mt16 text-center ft12 cl-info"
                  style="line-height: 18px"
                >
                  {{ item.scene }}
                </div>
                <div class="flex center mt24">
                  <a-button
                    @click="addMendian(item.app_id)"
                    type="primary"
                    ghost
                    >免费试用</a-button
                  >
                </div>
              </div>
              <img
                v-if="item.is_tui == 1"
                class="tui"
                src="../assets/image/label_recommend@2x.png"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <!--<div class="scheme-item">
              <div class="pt50">
                <div class="text-center">
                  <img
                    style="width: 80px; height: 80px"
                    src="../assets/image/application_icon_more@2x.png"
                  />
                </div>
                <div class="text-center mt16 ft18 cl-main ftw500">
                  更多解决方案正在研发中
                </div>
                <div class="mt16 cl-notice ft12 text-center">敬请期待…</div>
              </div>
            </div>-->
          </a-col>
        </a-row>
      </div>

      <a-modal
        title="创建商铺"
        :visible="showForm"
        @ok="handleOk"
        @cancel="handleCancel"
        :confirm-loading="confirmLoading"
        width="400px"
      >
        <div class="pd20_40">
          <a-input
            class="woyaou-input"
            placeholder="输入门店名称"
            prefix="店铺名"
            v-model="form.name"
          />
        </div>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showForm: false,
      loading: false,
      confirmLoading: false,
      account_id: 0,
      app_id: 0,
      search: {
        type: 1,
      },
      form: {
        name: "",
      },
      type_list: [],
      base_applications: [],
      applications: [],
      appnum: 0,
    };
  },
  created() {
    this.getLists();
  },
  methods: {
    getLists() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/showApp", {
          type: this.search.type,
        })
        .then((res) => {
          this.type_list = res.type_list;
          this.base_applications = res.base_applications;
          this.applications = res.applications;
          this.account_id = res.account_id;
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },

    selectIt(id) {
      this.search.type = id;
      this.getLists();
    },

    handleOk() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .api("platform/account/createShop", {
          app_id: this.app_id,
          name: this.form.name,
        })
        .then((res) => {
          this.confirmLoading = false;
          this.showForm = false;
          this.$message.success("创建商铺成功", 1, () => {
            this.$router.go(-1);
            window.open(
                res.base_url +
                "auth?shop_token=" +
                encodeURIComponent(res.shop_token)
            );
          });
        })
        .catch((res) => {
          this.confirmLoading = false;
        });
    },
    handleCancel() {
      this.showForm = false;
    },
    addMendian(app_id) {
      this.app_id = app_id;
      this.form = {
        name: "",
      };
      this.showForm = true;
    },
  },
};
</script>

<style>
.scheme-item {
  width: 100%;
  height: 326px;
  background: #ffffff;
  border: 1px solid #ebedf5;
  border-radius: 4px;
  transition: all 0.3s;
  position: relative;
  line-height: 1;
  padding: 30px;
}
.scheme-item:hover {
  transform: translateY(-10px);
}
.scheme-item .tui {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
}

.base-app-item {
  width: 580px;
  height: 140px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #ebedf5;
  transition: all 0.3s;
  position: relative;
  line-height: 1;
}
.base-app-item:hover {
  transform: translateY(-10px);
}
.base-app-item .tui {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
}
.base-app-l {
  width: 408px;
}
.base-app-l img {
  width: 100px;
  height: 100px;
}
.base-app-info {
  width: 288px;
}
.test-tag {
  height: 20px;
  line-height: 20px;
  background: rgba(71, 114, 255, 0.1);
  border-radius: 10px;
  color: rgba(71, 114, 255, 1);
  font-size: 12px;
  text-align: center;
  padding: 0 10px;
}
.app-cat {
  margin-left: 50px;
}
.app-cat-item {
  margin-right: 16px;
  padding: 0px 20px;
  height: 32px;
  border-radius: 16px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #232529;
  background: #ffffff;
}
.app-cat-item.on {
  background: #4772ff;
  color: #ffffff;
}
</style>